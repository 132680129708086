html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'DINRoundPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* tiptap editor override */

.ProseMirror {
  padding: 8.5px 14px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}

.ProseMirror-focused {
  padding: 7.5px 13px;
  border-width: 2px;
  border-color: #A10037;
  outline: none;
}